<template>
  <div id="flow.components.coolingCheck">
      <b-form-checkbox
      :id="checkBoxId + '-cooling'"
      v-model="status"
      name="checkbox-cooling"
      value="true"
      unchecked-value="false"
    >
        {{$t('flow.form.buttons.addCooling')}}
    </b-form-checkbox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'flow.components.coolingCheck',
    props: ['index'],
    computed: {
        ...mapGetters({
            loadCases: "getFlowLoadCases"
        }),
        loadCase() {
            return this.loadCases[this.index]
        },
        checkBoxId() {
            return 'checkbox-' + this.index
        },
        status: {
            get() {
                return this.loadCase.cooling
            },
            set(value) {
                this.$store.commit("setFlowCooling", {value: value === "true" ? true : false, index: this.index})
            }
        }
    }
}
</script>

<style>

</style>