<template>
  <div id="flow.components.removeButton">
    <b-button pill variant="outline-danger" @click="removeLoadCase(index)" :class="{ 'mr-2': current}">
      <div class="h4 m-0 p-0">
        <i class="fas fa-minus"></i>
      </div>
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "flow.components.removeButton",
  props: ['index'],
  computed: {
      ...mapGetters({
          loadCasesCount: "getFlowLoadCasesCount"
      }),
      current() {
          return this.loadCasesCount === (this.index + 1)
      }
  },
  methods: {
    removeLoadCase(index) {
        this.$store.dispatch("removeFlowLoadCase", index)
    }
  }
};
</script>

<style>
</style>