<template>
  <div id="flow.outputs.water.items.pressure">
    <b-row class="d-flex align-items-end">
      <b-col md="12" class="text-center">
        <label>{{$t('flow.outputs.water.items.cooling.label')}}:</label>
        <br />
        <b-row>
          <b-col>
            {{ result.mdotc[0] }} {{ result.mdotc[1] }} 
          </b-col>
          <b-col>
            {{ precentage(result.mdotc[0], result.mdoti[0]) }} % of total flow
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "flow.outputs.water.items.pressure",
  props: ['result'],
  methods: {
    precentage(mdotc, mdoti) {
      return Math.round(mdotc/mdoti * 100)
    }
  }
};
</script>

<style>
</style>