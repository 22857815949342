<template>
  <div id="flow.outputs.water.items.pressure">
    <b-row class="d-flex align-items-end">
      <b-col sm="12" class="text-center">
        <label>{{$t('flow.outputs.water.items.flashing.label')}}:</label>
        <br />
        {{ precentage(result.xe[0]) }} % {{$t('flow.outputs.water.items.flashing.amount')}}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "flow.outputs.water.items.pressure",
  props: ['result'],
  methods: {
      precentage(value) {
          return Math.round(value * 100)
      }
  }
};
</script>

<style>
</style>