<template>
  <div id="flow.form.design.loadCase">
    <b-input-group class="mr-2 mt-1">
      <b-form-select
        id="inputSelectOne-live"
        v-model="$v.resultType.$model"
        ref="focusThis"
        aria-describedby="inputSelectOne-feedback"
      >
      <b-form-select-option
        v-for="index in this.loadCases"
        :key="index"
        :value="index"
        >{{`Load case #${index + 1}`}}</b-form-select-option>
      </b-form-select>
    </b-input-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
export default {
  name: "flow.form.design.loadCase",
  props: ["index"],
  computed: {
    ...mapGetters({
      loadCases: "getFlowDesignLoadCases",
      design: "getFlowDesign"
    }),
    resultType: {
      get() {
        return this.design.selected
      },
      set(value) {
        this.$store.commit("setFlowDesignSelected", value)
      }
    }
  },
  validations: {
    resultType: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
};
</script>

<style>
</style>