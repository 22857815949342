import { render, staticRenderFns } from "./addButton.vue?vue&type=template&id=5726b682&"
import script from "./addButton.vue?vue&type=script&lang=js&"
export * from "./addButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports