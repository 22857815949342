<template>
  <div id="flow.components.chokedPopup">
    <h4><b-badge variant="danger"><i class="fas fa-exclamation-circle"></i> {{$t('flow.form.buttons.choked')}}</b-badge></h4>
    <!-- <div class="h4 m-0 p-0 text-danger" :id="'choked #' + index"><i class="fas fa-exclamation-circle"></i></div>
    <b-popover 
    :target="'choked #' + index"
    triggers="hover focus"
    >
        <template #title>Choked flow!</template>
        The exiting flow is choked and can decrease no further in pressure
    </b-popover> -->
  </div>
</template>

<script>
export default {
  name: "flow.components.chokedPopup",
  props: ['index']
};
</script>

<style>
</style>