<template>
  <div id="flow.outputs.water.items.temperature">
    <b-row class="mt-2 d-flex align-items-end">
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.velocity.labelVi')}}:</label>
        <br />
        {{ result.veli[0] }} {{ result.veli[1] }}
      </b-col>
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.velocity.labeldV')}}:</label>
        <br />
        <span v-if="(result.veli[0] - result.vele[0]) < 0">
            &uarr; {{ result.dV[0] }}
        </span>
        <span v-else-if="(result.veli[0] - result.vele[0]) > 0">
            &darr; {{ result.dV }}
        </span>
        <span v-else>0</span>
        {{ result.veli[1] }}
      </b-col>
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.velocity.labelVe')}}:</label>
        <br />
        {{ result.vele[0] }} {{ result.vele[1] }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "flow.outputs.water.items.temperature",
  props: ['result'],
  methods: {
      absolute(value) {
          return Math.abs(value)
      },
      round(value, dec) {
          return Number.parseFloat(value).toFixed(dec)
      }
  }
};
</script>

<style>
</style>