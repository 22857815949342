<template>
    <b-button 
    variant="success"
    :disabled="!active"
    @click="clicked()"
    ><i class="fas fa-calculator"></i> {{$t('flow.form.buttons.calculate')}}</b-button>
</template>

<script>
export default {
    name: 'flow.components.calculateButton',
    props: ['index', 'active'],
    methods: {
        clicked() {
            this.$emit('clicked')
        }
    }
}
</script>

<style>

</style>