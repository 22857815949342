<template>
  <div id="flow.outputs.water.items.pressure">
    <b-row class="d-flex align-items-end">
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.pressure.labelPi')}}:</label>
        <br />
        {{ result.Pi[0] }} {{ result.Pi[1] }}
      </b-col>
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.pressure.labeldP')}}:</label>
        <br />
        <span v-if="result.dP[0] < 0">
            &darr; {{ absolute(result.dP[0]) }}
        </span>
        <span v-else-if="result.dP[0] > 0">
            &darr; {{ result.dP[0] }}
        </span>
        <span v-else>0</span>
        {{ result.dP[1] }}
      </b-col>
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.pressure.labelPe')}}:</label>
        <br />
        {{ result.Pe[0] }} {{ result.Pe[1] }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "flow.outputs.water.items.pressure",
  props: ['result'],
  methods: {
      absolute(value) {
          return Math.abs(value)
      }
  }
};
</script>

<style>
</style>