<template>
  <div id="inletMassFlow">
    <b-form-group class="mb-2" id="mdot-group">
      <label for="mdot-live">{{$t('flow.form.properties.mdot.label')}}: {{$t('flow.form.general.required')}}</label>
      <b-input-group>
        <template v-slot:append>
          <b-form-select
            id="mdot-live"
            tabindex="-1"
            v-model="mdotUnit"
            :options="$t('flow.form.properties.mdot.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="mdot-live"
          type="number"
          v-model="$v.mdot.$model"
          :state="validateState('mdot')"
          aria-describedby="mdot-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.mdot.$anyError" id="mdot-feedback">
        <small v-if="!$v.mdot.required" class="form-text text-danger">{{$t('loss.form.properties.mdot.required')}}</small>
        <small v-if="!$v.mdot.valid" class="form-text text-danger">
          {{$t('loss.form.properties.mdot.greater', {minMassFlow: getLowerBoundery(mdotUnit), unit: $tc('units.mdot.short',getMassFlowUnit(mdotUnit))})}}
        </small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from "@/scripts";
export default {
  name: "flow.form.massFlow",
  props: ['index'],
  computed: {
    ...mapGetters({
      loadCases: "getFlowLoadCases",
    }),
    mdot: {
      get() {
        return this.loadCases[this.index].form.mdot.value;
      },
      set(value) {
        this.$store.dispatch("storeFlowMassFlowValue", {
          value: value,
          index: this.index
        });
      },
    },
    mdotUnit: {
      get() {
        return this.loadCases[this.index].form.mdot.unit;
      },
      set(value) {
        this.$store.dispatch("storeFlowMassFlowUnit", {
          value: value,
          index: this.index
        });
      },
    },
  },
  validations: {
    mdot: {
      required,
      valid() {
        return logic.input.flow.mdot.validate(
          this.loadCases[this.index].form.mdot.value,
          this.loadCases[this.index].form.mdot.unit
        );
      },
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundery(units) {
      if (units === "lb/s") {
        return logic.input.flow.mdot.lb_s.min;
      } else if (units === "ton/h") {
        return logic.input.flow.mdot.ton_h.min;
      } else {
        return logic.input.flow.mdot.kg_s.min;
      }
    },
    getUpperBoundery(units) {
      if (units === "lb/s") {
        return logic.input.flow.mdot.lb_s.max;
      } else if (units === "ton/h") {
        return logic.input.flow.mdot.ton_h.max;
      } else {
        return logic.input.flow.mdot.kg_s.max;
      }
    },
    getMassFlowUnit(units) {
      if (units === "lb/s") {
        return 1;
      } else if (units === "ton/h") {
        return 2;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
</style>