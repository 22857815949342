<template>
  <div id="flow.outputs.water.flowCoefficient.index">
    <messages v-bind:index="index" v-bind:result="result" />
    <flow-coefficient v-bind:result="result" />
    <br>
    <div v-if="result.cooling === true">
      <cooling v-bind:result="result" />
      <br>
    </div>
    <pressure v-bind:result="result" />
    <temperature v-bind:result="result" />
    <div v-if="result.xe > result.xi">
      <br>
      <flashing v-bind:result="result" />
    </div>
    <div v-if="result.sizing ===  true">
      <br>
      <velocity v-bind:result="result" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import messages from '../../messages'
import flowCoefficient from '../items/flowValue'
import pressure from '../items/pressure'
import temperature from '../items/temperature'
import flashing from '../items/flashing'
import velocity from '../items/velocity'
import cooling from '../items/cooling'
export default {
  name: "flow.outputs.water.flowCoefficient.index",
  props: ['index'],
  components: {
    messages,
    flowCoefficient,
    pressure,
    temperature,
    flashing,
    velocity,
    cooling
  },
  computed: {
      ...mapGetters({
        loadCases: "getFlowLoadCases"
      }),
      result() {
        return this.loadCases[this.index].result
      }
    }
};
</script>

<style>
</style>