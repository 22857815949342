<template>
  <div id="flow.components.caviationPopup">
    <h4><b-badge variant="danger"><i class="fas fa-exclamation-circle"></i> {{$t('flow.form.buttons.cavitation')}}</b-badge></h4>
    <!-- <div class="h4 m-0 p-0 text-danger" :id="'cavitation #' + index"><i class="fas fa-exclamation-circle"></i></div>
    <b-popover 
    :target="'cavitation #' + index"
    triggers="hover focus"
    >
        <template #title>Danger of Cavitation!</template>
        The flow is of danger of cavitation potentially damaging the valve
    </b-popover> -->
  </div>
</template>

<script>
export default {
  name: "flow.components.chokedPopup",
  props: ['index']
};
</script>

<style>
</style>