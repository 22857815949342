<template>
  <div id="inletTemperature">
    <b-form-group class="mb-2" id="Tc-group">
      <label for="Tc-live">{{$t('flow.form.properties.Tc.label')}}: {{$t('flow.form.general.required')}}</label>
      <b-input-group>
        <template v-slot:append>
          <b-form-select
            id="Tc-live"
            tabindex="-1"
            v-model="TtUnit"
            :options="$t('flow.form.properties.Tc.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="Tc-live"
          type="number"
          v-model="$v.Tc.$model"
          :state="validateState('Tc')"
          aria-describedby="Tc-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.Tc.$anyError" id="Tc-feedback">
        <small v-if="!$v.Tc.required" class="form-text text-danger">{{$t('flow.form.properties.Tc.required')}}</small>
        <small v-if="!$v.Tc.valid" class="form-text text-danger">
          {{$t('flow.form.properties.Tc.between', {minTemp: getLowerBoundery(TtUnit), maxTemp: getUpperBoundery(TtUnit), unit: $tc('units.temperature.short',getTemperatureUnit(TtUnit))})}}
        </small>
        <small
            v-if="!$v.Tc.lower"
            class="form-text text-danger"
          >{{$t('flow.form.properties.Tc.smaller')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from "@/scripts";
export default {
  name: "flow.form.targetTemperature",
  props: ['index'],
  computed: {
    ...mapGetters({
      loadCases: "getFlowLoadCases",
    }),
    Tc: {
      get() {
        return this.loadCases[this.index].form.Tc.value;
      },
      set(value) {
        this.$store.dispatch("storeFlowTcValue", {
          value: value,
          index: this.index
        });
      },
    },
    TtUnit: {
      get() {
        return this.loadCases[this.index].form.Tc.unit;
      },
      set(value) {
        this.$store.dispatch("storeFlowTcUnit", {
          value: value,
          index: this.index
        });
      },
    },
  },
  validations: {
    Tc: {
      required,
      valid() {
        return logic.input.flow.temperature.validate(
          this.loadCases[this.index].form.Tc.value,
          this.loadCases[this.index].form.Tc.unit
        );
      },
      lower() {
        return logic.input.pressure.lower(
          this.loadCases[this.index].form.Tc.value,
          this.loadCases[this.index].form.Tc.unit,
          this.loadCases[this.index].form.Tt.value,
          this.loadCases[this.index].form.Tt.unit,
        )
      }
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundery(units) {
      if (units === "degf") {
        return logic.input.flow.temperature.degf.min;
      } else if (units === "kelvin") {
        return logic.input.flow.temperature.kelvin.min;
      } else {
        return logic.input.flow.temperature.degc.min;
      }
    },
    getUpperBoundery(units) {
      if (units === "degf") {
        return logic.input.flow.temperature.degf.max;
      } else if (units === "kelvin") {
        return logic.input.flow.temperature.kelvin.max;
      } else {
        return logic.input.flow.temperature.degc.max;
      }
    },
    getTemperatureUnit(units) {
      if (units === "degf") {
        return 1;
      } else if (units === "kelvin") {
        return 2;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
</style>