<template>
  <div id="outletPressure">
    <b-form-group class="mb-2" id="Pe-group">
      <label for="Pe-live">{{$t('flow.form.properties.Pe.label')}}: {{$t('flow.form.general.required')}}</label>
      <b-input-group>
        <template v-slot:append>
          <b-form-select
            id="Pe-live"
            tabindex="-1"
            v-model="PeUnit"
            :options="$t('flow.form.properties.Pe.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="Pe-live"
          type="number"
          v-model="$v.Pe.$model"
          :state="validateState('Pe')"
          aria-describedby="Pe-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.Pe.$anyError" id="Pe-feedback">
        <small v-if="!$v.Pe.required" class="form-text text-danger">{{$t('flow.form.properties.Pe.required')}}</small>
        <small v-if="!$v.Pe.valid" class="form-text text-danger">
          {{$t('flow.form.properties.Pe.between', {minPres: getLowerBoundery(PeUnit), maxPres: getUpperBoundery(PeUnit), unit: $tc('units.pressure.short',getPressureUnit(PeUnit))})}}
        </small>
        <small
            v-if="!$v.Pe.lower"
            class="form-text text-danger"
          >{{$t('flow.form.properties.Pe.smaller')}}</small>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from "@/scripts";
export default {
  name: "flow.form.outletPressure",
  props: ['index'],
  computed: {
    ...mapGetters({
      loadCases: "getFlowLoadCases",
    }),
    Pe: {
      get() {
        return this.loadCases[this.index].form.Pe.value;
      },
      set(value) {
        this.$store.dispatch("storeFlowPeValue", {
          value: value,
          index: this.index
        });
      },
    },
    PeUnit: {
      get() {
        return this.loadCases[this.index].form.Pe.unit;
      },
      set(value) {
        this.$store.dispatch("storeFlowPeUnit", {
          value: value,
          index: this.index
        });
      },
    },
  },
  validations: {
    Pe: {
      required,
      valid() {
        return logic.input.flow.pressure.validate(
          this.loadCases[this.index].form.Pe.value,
          this.loadCases[this.index].form.Pe.unit
        );
      },
      lower() {
        return logic.input.pressure.lower(
          this.loadCases[this.index].form.Pe.value,
          this.loadCases[this.index].form.Pe.unit,
          this.loadCases[this.index].form.Pi.value,
          this.loadCases[this.index].form.Pi.unit,
        )
      }
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundery(units) {
      if (units === "psi") {
        return logic.input.flow.pressure.psi.min;
      } else if (units === "mpa") {
        return logic.input.flow.pressure.mpa.min;
      } else {
        return logic.input.flow.pressure.bar.min;
      }
    },
    getUpperBoundery(units) {
      if (units === "psi") {
        return logic.input.flow.pressure.psi.max;
      } else if (units === "mpa") {
        return logic.input.flow.pressure.mpa.max;
      } else {
        return logic.input.flow.pressure.bar.max;
      }
    },
    getPressureUnit(units) {
      if (units === "psi") {
        return 1;
      } else if (units === "mpa") {
        return 2;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
</style>