<template>
  <div id="flow.components.flashingPopup">
    <h4><b-badge variant="danger"><i class="fas fa-exclamation-circle"></i> {{$t('flow.form.buttons.flashing')}}</b-badge></h4>
    <!-- <div class="h4 m-0 p-0 text-danger" :id="'flashing #' + index"><i class="fas fa-exclamation-circle"></i></div>
    <b-popover 
    :target="'flashing #' + index"
    triggers="hover focus"
    >
        <template #title>Flashing flow!</template>
        The exiting flow is flashing potentially damaging the material behind the valve
    </b-popover> -->
  </div>
</template>

<script>
export default {
  name: "flow.components.chokedPopup",
  props: ['index']
};
</script>

<style>
</style>