<template>
  <div id="flow.outputs.water.items.massflow">
    <b-row class="d-flex align-items-end">
      <b-col sm="12" class="text-center">
        <label>{{$t('flow.outputs.water.items.massFlow.label')}}:</label>
        <br />
        {{ result.mdoti[0] }} {{ result.mdoti[1] }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "flow.outputs.water.items.massflow",
  props: ['result'],
  methods: {
      absolute(value) {
          return Math.abs(value)
      }
  }
};
</script>

<style>
</style>