<template>
  <div id="partoutletSizeDin">
    <b-form-group class="mb-2 border rounded p-2">
      <label>{{$t('sonic.form.piping.outletSize.label', {type: $tc('sonic.form.piping.type.types',1)})}}:</label>
      <b-row>
        <b-col md="12">
          <b-input-group class="mb-2">
            <b-input-group>
              <b-form-select id="size-outlet-valve-live" v-model="outletSize">
                <b-form-select-option
                  :value="null"
                  :disabled="true"
                >{{$t('sonic.form.piping.outletSize.emptySelect')}}</b-form-select-option>
                <b-form-select-option
                  v-for="item in loadCase.form.outletSizing.sizeList"
                  :key="item.id"
                  :value="item.DIN"
                >{{`DN ${item.DIN}`}}</b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-input-group>
        </b-col>
        <b-col md="12">
          <b-input-group class="mb-2">
            <b-input-group>
              <b-form-select id="size-outlet-valve-live" v-model="outletNorm">
                <b-form-select-option
                  :value="null"
                  :disabled="true"
                >{{$t('sonic.form.piping.outletNorm.emptySelect')}}</b-form-select-option>
                <b-form-select-option
                  v-for="(item, key) in loadCase.form.outletSizing.normList"
                  :key="key"
                  :value="key"
                >{{`PN ${key}`}}</b-form-select-option>
                <b-form-select-option
                  :value="null"
                  :disabled="true"
                >{{$t('sonic.form.piping.inletNorm.footNote')}}</b-form-select-option>
              </b-form-select>
            </b-input-group>
          </b-input-group>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from "vuelidate/lib/validators";
export default {
  name: "flow.form.outletSize.din",
  props: ['index'],
  computed: {
    ...mapGetters({
      loadCases: "getFlowLoadCases",
    }),
    loadCase() {
      return this.loadCases[this.index]
    },
    outletSize: {
      get() {
        return this.loadCase.form.outletSizing.size;
      },
      set(value) {
        this.$store.dispatch("storeFlowOutletSize", {size: value, index: this.index});
      },
    },
    outletNorm: {
      get() {
        return this.loadCase.form.outletSizing.norm;
      },
      set(value) {
        this.$store.commit("setFlowOutletNorm", {value: value, index: this.index});
      },
    },
  },
  validations: {
    outletSize: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  }
};
</script>

<style>
</style>