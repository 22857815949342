<template>
  <div id="flow.outputs.water.index">
    <b-row v-if="loadCases[index].selected === 'pressure'">
      <b-col md="12">
        <pressure v-bind:index="index" />
      </b-col>
    </b-row>
    <b-row v-if="loadCases[index].selected === 'flowCoefficient'">
      <b-col md="12">
        <flow-coefficient v-bind:index="index" />
      </b-col>
    </b-row>
    <b-row v-if="loadCases[index].selected === 'massFlow'">
      <b-col md="12">
        <mass-flow v-bind:index="index" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pressure from './pressure'
import massFlow from './massFlow'
import flowCoefficient from './flowCoefficient'
export default {
    name: 'flow.outputs.water.index',
    props: ['index'],
    components: {
      pressure,
      massFlow,
      flowCoefficient
    },
    computed: {
      ...mapGetters({
        loadCases: "getFlowLoadCases"
      })
    }
}
</script>

<style>

</style>