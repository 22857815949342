<template>
  <div id="flow.inputs.water.offDesign.index">
    Off-design
  </div>
</template>

<script>
export default {
  name: "flow.inputs.water.offDesign.index",
};
</script>

<style>
</style>