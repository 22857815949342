<template>
  <div id="flow.outputs.water.messages">
    <b-row class="d-flex flex-row-reverse">
      <choked-popup v-if="result.choked === true" class="mr-4" v-bind:index="index" />
      <flashing-popup v-if="result.flashing === true" class="mr-4" v-bind:index="index" />
      <cavitation-popup v-if="result.cavitation === true" class="mr-4" v-bind:index="index" />     
      <vel-limit-popup v-if="result.limit > 0 && result.sizing === true" class="mr-4" v-bind:index="index" v-bind:limit="result.limit" />      
    </b-row>
  </div>
</template>

<script>
import chokedPopup from '../../components/chokedPopup'
import flashingPopup from '../../components/flashingPopup'
import cavitationPopup from '../../components/cavitationPopup'
import velLimitPopup from '../../components/velLimitPopup'
export default {
  name: "flow.outputs.water.messages",
  props: ['index', 'result'],
  components: {
      chokedPopup,
      flashingPopup,
      cavitationPopup,
      velLimitPopup
  }
};
</script>

<style>
</style>