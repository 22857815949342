<template>
  <div id="flow.components.diameterCheck">
      <b-form-checkbox
      :id="checkBoxId + '-diameter'"
      v-model="status"
      name="checkbox-sizing"
      value="true"
      unchecked-value="false"
    >
        {{$t('flow.form.buttons.addValveSize')}}
    </b-form-checkbox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'flow.components.diameterCheck',
    props: ['index'],
    computed: {
        ...mapGetters({
            loadCases: "getFlowLoadCases"
        }),
        loadCase() {
            return this.loadCases[this.index]
        },
        checkBoxId() {
            return 'checkbox-' + this.index
        },
        status: {
            get() {
                return this.loadCase.sizing
            },
            set(value) {
                this.$store.commit("setFlowSizing", {value: value === "true" ? true : false, index: this.index})
            }
        }
    }
}
</script>

<style>

</style>