<template>
  <div id="flow.components.velLimitPopup">
    <div v-if="limit == 1">
      <h4><b-badge variant="warning"><i class="fas fa-exclamation-triangle"></i> {{$tc('flow.form.buttons.velLimit', 1)}}</b-badge></h4>
    </div>
    <div v-else-if="limit ==2">
      <h4><b-badge variant="danger"><i class="fas fa-exclamation-circle"></i> {{$tc('flow.form.buttons.velLimit', 2)}}</b-badge></h4>
    </div>
  </div>
</template>

<script>
export default {
  name: "flow.components.velLimitPopup",
  props: ['index', 'limit']
};
</script>

<style>
</style>