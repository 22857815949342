<template>
  <div id="partOutletSizing">
    <b-row>
      <b-col md="12">
        <b-form-group class="mt-2">
          <label for="sizing-inlet-live"
            >{{ $t("flow.form.sizing.inletSizing.label") }}:</label
          >
          <b-input-group class="mb-0">
            <b-form-select
              id="sizing-nlet-live"
              v-model="$v.outletSizing.$model"
              :state="validateState('outletSizing')"
              :options="$t('flow.form.sizing.inletSizing.options')"
              :disabled="true"
            ></b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <div v-if="outletSizing === 'DIN'">
          <din v-bind:index="index" />
        </div>
        <div v-else-if="outletSizing === 'ANSI'">
          <ansi v-bind:index="index" />
        </div>
        <div v-else>
          <costum v-bind:index="index" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import din from './outletSizeDIN'
import ansi from './outletSizeANSI'
import costum from './outletSizeCostum'
export default {
  name: "sonic.part.outlet.sizing",
  props: ['index'],
  components: {
    din,
    ansi,
    costum
  },
  computed: {
    ...mapGetters({
      loadCases: "getFlowLoadCases",
    }),
    outletSizing: {
      get() {
        return this.loadCases[this.index].form.inletSizing.standard;
      },
      set(value) {
        this.$store.dispatch("storeFlowOutletStandard", { standard: value, index: this.index });
      },
    },
  },
  validations: {
    outletSizing: {
      required,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>

<style>
</style>