<template>
  <div id="coolingPressure">
    <b-form-group class="mb-2" id="Pc-group">
      <label for="Pc-live">{{$t('flow.form.properties.Pc.label')}}: {{$t('flow.form.general.required')}}</label>
      <b-input-group>
        <template v-slot:append>
          <b-form-select
            id="Pc-live"
            tabindex="-1"
            v-model="PcUnit"
            :options="$t('flow.form.properties.Pc.options')"
          ></b-form-select>
        </template>
        <b-form-input
          id="Pc-live"
          type="number"
          v-model="$v.Pc.$model"
          :state="validateState('Pc')"
          aria-describedby="Pc-feedback"
        ></b-form-input>
      </b-input-group>
      <div v-if="$v.Pc.$anyError" id="Pc-feedback">
        <small v-if="!$v.Pc.required" class="form-text text-danger">{{$t('flow.form.properties.Pc.required')}}</small>
        <small v-if="!$v.Pc.valid" class="form-text text-danger">
          {{$t('flow.form.properties.Pc.between', {minPres: getLowerBoundery(PcUnit), maxPres: getUpperBoundery(PcUnit), unit: $tc('units.pressure.short',getPressureUnit(PcUnit))})}}
        </small>
        <!-- <small
            v-if="!$v.Pc.lower"
            class="form-text text-danger"
          >{{$t('flow.form.properties.Pc.smaller')}}</small> -->
      </div>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import logic from "@/scripts";
export default {
  name: "flow.form.coolingPressure",
  props: ['index'],
  computed: {
    ...mapGetters({
      loadCases: "getFlowLoadCases",
    }),
    Pc: {
      get() {
        return this.loadCases[this.index].form.Pc.value;
      },
      set(value) {
        this.$store.dispatch("storeFlowPcValue", {
          value: value,
          index: this.index
        });
      },
    },
    PcUnit: {
      get() {
        return this.loadCases[this.index].form.Pc.unit;
      },
      set(value) {
        this.$store.dispatch("storeFlowPcUnit", {
          value: value,
          index: this.index
        });
      },
    },
  },
  validations: {
    Pc: {
      required,
      valid() {
        return logic.input.flow.pressure.validate(
          this.loadCases[this.index].form.Pc.value,
          this.loadCases[this.index].form.Pc.unit
        );
      },
      // lower() {
      //   return logic.input.pressure.lower(
      //     this.loadCases[this.index].form.Pe.value,
      //     this.loadCases[this.index].form.Pe.unit,
      //     this.loadCases[this.index].form.Pc.value,
      //     this.loadCases[this.index].form.Pc.unit,
      //   )
      // }
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    getLowerBoundery(units) {
      if (units === "psi") {
        return logic.input.flow.pressure.psi.min;
      } else if (units === "mpa") {
        return logic.input.flow.pressure.mpa.min;
      } else {
        return logic.input.flow.pressure.bar.min;
      }
    },
    getUpperBoundery(units) {
      if (units === "psi") {
        return logic.input.flow.pressure.psi.max;
      } else if (units === "mpa") {
        return logic.input.flow.pressure.mpa.max;
      } else {
        return logic.input.flow.pressure.bar.max;
      }
    },
    getPressureUnit(units) {
      if (units === "psi") {
        return 1;
      } else if (units === "mpa") {
        return 2;
      } else {
        return 0;
      }
    },
  },
};
</script>

<style>
</style>