<template>
  <div id="flow.inputs.water.load.index">
    <b-row class="d-flex justify-content-center">
      <b-col md="6" v-for="(loadCase, index) in loadCases" v-bind:key="index">
        <b-card header-tag="nav" class="mt-4 mb-4">
          <template v-slot:header>
            <b-navbar toggleable="sm" class="text-center">
              <b-navbar-brand href="#">
                {{$t('flow.inputs.water.load.title')}} #{{index + 1}}
              </b-navbar-brand>
              <b-navbar-nav>
                <result-type v-bind:index="index" />
              </b-navbar-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
                <div v-if="loadCasesCount > settings.stored.min">
                    <removeButton v-bind:index="index" />
                </div>
                <div v-if="loadCasesCount < settings.stored.max && loadCasesCount === (index + 1)">
                    <addButton />
                </div>
              </b-navbar-nav>
            </b-navbar>
          </template>
          <b-row v-if="request.message && request.index === index">
            <b-col md="12">
              <b-alert
              show
              variant="danger"
              class="text-center"
              >{{request.message}}</b-alert>
            </b-col>
          </b-row>
          <b-row v-if="loadCase.selected === 'pressure'">
            <b-col md="12">
              <pressure v-bind:index="index" />
            </b-col>
          </b-row>
          <b-row v-if="loadCase.selected === 'massFlow'">
            <b-col md="12">
              <massFlow v-bind:index="index" />
            </b-col>
          </b-row>
          <b-row v-if="loadCase.selected === 'flowCoefficient'">
            <b-col md="12">
              <flowCoefficient v-bind:index="index" />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="ml-1">{{$t('flow.form.general.required-text')}}</b-col>
          </b-row>
          <div v-if="loadCase.result">
            <hr>
            <outputs v-bind:index="index" />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pressure from "./pressure";
import massFlow from "./massflow";
import flowCoefficient from "./flowCoefficient";
import outputs from '../../../outputs/water/load/index'
import resultType from '../../../form/resultType'
import addButton from "../../../components/addButton"
import removeButton from "../../../components/removeButton"
export default {
  name: "flow.inputs.water.load.index",
  components: {
    pressure,
    massFlow,
    flowCoefficient,
    outputs,
    resultType,
    addButton,
    removeButton
  },
  computed: {
    ...mapGetters({
      loadCases: "getFlowLoadCases",
      loadCasesCount: "getFlowLoadCasesCount",
      settings: "getFlowSettings",
      request: "getFlowRequest"
    }),
  },
};
</script>

<style>
</style>