<template>
  <div id="flow.inputs.water.design.index">
    <b-row class="d-flex justify-content-center">
      <b-col md="8">
        <b-card header-tag="nav" class="mt-4 mb-4">
          <template v-slot:header>
            <b-navbar toggleable="sm">
              <b-navbar-brand href="#">
                {{$t('flow.inputs.water.design.title')}}
              </b-navbar-brand>
              <b-navbar-nav>
                <load-case />
              </b-navbar-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto">
              </b-navbar-nav>
            </b-navbar>
          </template>
          <!-- <b-row v-if="request.message">
            <b-col md="12">
              <b-alert
              show
              variant="danger"
              class="text-center"
              >{{request.message}}</b-alert>
            </b-col>
          </b-row> -->
          <b-row>
              <b-col md="12">
                  <flow-value v-bind:result="result" />
              </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="ml-1">{{$t('flow.form.general.required-text')}}</b-col>
          </b-row>
          <!-- <div v-if="loadCase.result">
            <hr>
            <outputs v-bind:index="index" />
          </div> -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loadCase from '../../../form/design/loadCase'
import flowValue from './items/flowValue'
export default {
  name: "flow.inputs.water.design.index",
  components: {
    loadCase,
    flowValue
  },
  computed: {
      ...mapGetters({
          loadCasesList: "getFlowDesignLoadCases",
          loadCases: "getFlowLoadCases",
          design: "getFlowDesign"
      }),
      result() {
          return this.loadCases[this.design.selected].result
      }
  },
  created() {
      if (this.loadCasesList.length <= 0) {
          this.$router.push({name: "flow.load"})
      }
  }
};
</script>

<style>
</style>