<template>
  <div id="flow.components.addButton">
    <b-button pill variant="outline-success" @click="addLoadCase()">
      <div class="h4 m-0 p-0">
        <i class="fas fa-plus"></i>
      </div>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "flow.components.addButton",
  methods: {
    addLoadCase() {
        this.$store.dispatch("addFlowLoadCase")
    }
  }
};
</script>

<style>
</style>