<template>
  <div id="flow.outputs.water.items.temperature">
    <b-row class="mt-2 d-flex align-items-end">
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.temperature.labelTi')}}:</label>
        <br />
        {{ result.Ti[0] }}
        <span v-if="result.Te[1] === 'degc'">&#176;C</span>
        <span v-else-if="result.Te[1] === 'degf'">&#176;F</span>
        <span v-else>K</span>
      </b-col>
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.temperature.labeldT')}}:</label>
        <br />
        <span v-if="result.dT[0] < 0">
            &uarr; {{ absolute(result.dT[0]) }}
        </span>
        <span v-else-if="result.dT[0] > 0">
            &darr; {{ result.dT[0] }}
        </span>
        <span v-else>0</span>
        <span v-if="result.Te[1] === 'degc'">&#176;C</span>
        <span v-else-if="result.Te[1] === 'degf'">&#176;F</span>
        <span v-else>K</span>
      </b-col>
      <b-col sm="4" class="text-center">
        <label>{{$t('flow.outputs.water.items.temperature.labelTe')}}:</label>
        <br />
        {{ result.Te[0] }}
        <span v-if="result.Te[1] === 'degc'">&#176;C</span>
        <span v-else-if="result.Te[1] === 'degf'">&#176;F</span>
        <span v-else>K</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "flow.outputs.water.items.temperature",
  props: ['result'],
  methods: {
      absolute(value) {
          return Math.abs(value)
      }
  }
};
</script>

<style>
</style>