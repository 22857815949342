<template>
  <div id="flow.inputs.water.pressure.index">
    <b-row>
      <b-col md="6">
        <inlet-pressure v-bind:index="index" />
      </b-col>
      <b-col md="6">
        <inlet-temperature v-bind:index="index" />
      </b-col>
    </b-row>
    <mass-flow v-bind:index="index" />
    <flow-coefficient v-bind:index="index" />
    <cooling-check v-bind:index="index" />
    <diameter-check v-bind:index="index" />
    <!-- <div class="text-center">{{$t('flow.form.general.calculate')}}</div> -->
    <div v-if="loadCases[index].cooling" class="mt-2">
      <b-row>
        <b-col md="12">
          <target-temperature v-bind:index="index" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <cooling-pressure v-bind:index="index" />
        </b-col>
        <b-col md="6">
          <cooling-temperature v-bind:index="index" />
        </b-col>
      </b-row>
    </div>
    <hr v-if="loadCases[index].sizing && loadCases[index].cooling">
    <div v-if="loadCases[index].sizing">
      <b-row>
        <b-col md="6">
          <inlet-sizing v-bind:index="index" />
        </b-col>
        <b-col md="6">
          <outlet-sizing v-bind:index="index" />
        </b-col>
      </b-row>      
    </div>
    <b-row>
      <b-col md="12" class="d-flex justify-content-center">
        <calculate-button class="mt-2" v-bind:index="index" v-on:clicked="runCalculate()" :active="valid" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import logic from '@/scripts';
import inletPressure from '../../../../form/inletPressure'
import inletTemperature from '../../../../form/inletTemperature'
import massFlow from '../../../../form/massFlow'
import flowCoefficient from '../../../../form/flowCoefficient'
import coolingPressure from '../../../../form/coolingPressureWater'
import coolingTemperature from '../../../../form/coolingTemperatureWater'
import targetTemperature from '../../../../form/coolingTemperatureTarget'
import inletSizing from '../../../../form/inletSizing'
import outletSizing from '../../../../form/outletSizing'
import coolingCheck from '../../../../components/coolingCheck'
import diameterCheck from '../../../../components/diameterCheck'
import calculateButton from '../../../../components/calculateButton'
export default {
  name: "flow.inputs.water.pressure.index",
  props: ["index"],
  components: {
    inletPressure,
    inletTemperature,
    massFlow,
    flowCoefficient,
    coolingPressure,
    coolingTemperature,
    targetTemperature,
    inletSizing,
    outletSizing,
    coolingCheck,
    diameterCheck,
    calculateButton
  },
  computed: {
    ...mapGetters({
      loadCases: "getFlowLoadCases",
      request: "getFlowRequest"
    }),
    loadCase() {
      return this.loadCases[this.index]
    },
    valid() {
      if (this.loadCase.sizing) {
        return logic.input.flow.validatePressure(this.loadCase.form.Pi, this.loadCase.form.Ti, this.loadCase.form.mdot, this.loadCase.form.flowCoefficient) && logic.input.flow.validateSizing(this.loadCase.form.inletSizing, this.loadCase.form.outletSizing)
      } else {
        return logic.input.flow.validatePressure(this.loadCase.form.Pi, this.loadCase.form.Ti, this.loadCase.form.mdot, this.loadCase.form.flowCoefficient)
      }
    }
  },
  methods: {
    runCalculate() {
      this.$store.dispatch("runFlowCalculate", {index: this.index})
    }
  }
};
</script>

<style>
</style>